





































import Vue from 'vue'
import { Component } from "vue-property-decorator"

@Component
export default class ResponsivePartners extends Vue{
    data () {
      return {
        items: [
          {
            src: '/logos/Zoom.png',
            link: "https://zoom.us/",
            label: "Zoom"
          },
          {
            src: '/logos/canarie.png',
            link: "https://www.canarie.ca/",
            label: "Canarie"
          },
          {
            src: '/logos/yeswecan.jpeg', 
            link: "https://www.linkedin.com/in/yes-we-can-87932a1a6/",
            label: "Yes We Can"  
          },
        ],
      }
    }

}
