































































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class ResponsiveCarousel extends Vue {
  data() {
    return {
      rotate: true,
      hover: false,
      items: [
        {
          bg: "/imgs/bg_wave_tile.jpg",
          h1: "Anand has launched Anand's World",
          link: "#whatsNew",
          linkText: "Whats new",
        },
        {
          bg: "/imgs/bg_ipad_left.jpg",
          h1: "Currently in pilot phase",
          link: "#contact",
          linkText: "I'm interested",
        },
        {
          bg: "/imgs/bg_neuron_2400.jpg",
          h1: "Hear your loved ones",
          link: "#whatsNew",
          linkText: "About Us",

        },
        {
          h1: "Our motivation",
          bg: "/imgs/bg_galaxy.jpg",
          link: "#motivation",
          linkText: "Meet Anand",
          position: "top"
        },
        {
          bg: "/imgs/bg_ai.jpg",
          h1: "Our research partners",
          link: "#partners",
          linkText: "See our partners",
        },
      ],
    };
  }
}
