import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: {
                    base: "#1600FE",
                    lighten1: "#4433FF",
                    lighten2: "#695CFF",
                    lighten3: "#8F85FF",
                    lighten4: "#B4ADFF",
                    lighten5: "#DAD6FF",
                    darken3: "#0A0078",
                    darken4: "#050038"
                },
                sidebar: colors.grey.lighten3,
                topbar: "#1600FE",
                secondary: "#000000",
                accent: "#E2812B",
                background: "#00FF00",
                error: '#FF5252',
                info: "#E2812B",
                success: '#4CAF50',
                warning: '#da5335',
                subtle: colors.grey.lighten1
            },
            dark: {
                primary: {
                    base: "#1600FE",
                    darken3: "#363636",
                    darken4: "#020012"
                },
                secondary: "#000000",
                sidebar: "#0D0106",
                topbar: "#000000",
                accent: "#E16012",
                background: "#FFFFFF",
                error: '#FF5252',
                info: "#E16012",
                success: '#4CAF50',
                warning: '#da5335',
                subtle: colors.grey.lighten1

            },
        },
    },
});
