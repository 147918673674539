
















































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class ResponsiveAbout extends Vue {
  data() {
    return {
      rotate: true,
      hover: false,
      items: [
        {
          bg: "/imgs/bg_neuron_2400.jpg",
          h1: "Hear your loved ones",
        },
        {
          h1: "Motivation",
          bg: "/imgs/bg_galaxy.jpg",
          link: "#motivation",
          linkText: "Our motivation",
        },
        {
          bg: "/imgs/bg_wave_tile.jpg",
          h1: "Want a demo or want to connect?",
          link: "#interest",
          linkText: "I'm interested",
        },
        {
          bg: "/imgs/bg_ai.jpg",
          h1: "Our research partners",
          link: "#partners",
          linkText: "See our partners",
        },
      ],
    };
  }
}
