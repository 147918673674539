



















































import Vue from 'vue'
import { Component } from "vue-property-decorator"
import firebase from 'firebase'


// components
import ResponsiveForm from '@/components/ResponsiveForm.vue'
import ResponsiveText from "@/components/ResponsiveText.vue"
import ResponsiveActionIcons from "@/components/ResponsiveActionIcons.vue"
import ResponsiveImage from "@/components/ResponsiveImage.vue"
import ResponsiveAbout from "@/components/ResponsiveAbout.vue"
import ResponsiveMotivation from "@/components/ResponsiveMotivation.vue"
import ResponsiveWave from "@/components/ResponsiveWave.vue"
import ResponsiveInterested from "@/components/ResponsiveInterested.vue"
import ResponsiveCarousel from "@/components/ResponsiveCarousel.vue"
import ResponsivePartners from "@/components/ResponsivePartners.vue"
import ResponsiveWhatsNew from './components/ResponsiveWhatsNew.vue'
@Component({
  components: {
    ResponsiveForm,
    ResponsiveText,
    ResponsiveActionIcons,
    ResponsiveImage,
    ResponsiveAbout,
    ResponsiveMotivation,
    ResponsiveWave,
    ResponsiveInterested,
    ResponsiveCarousel,
    ResponsivePartners,
    ResponsiveWhatsNew
  }
})
export default class App extends Vue {
    mounted() {
      if (!firebase.apps.length) {
            firebase.initializeApp({
                apiKey: "AIzaSyBtUetEMyGkl_oYK3pdHRNav0yFVvgM-YE",
                authDomain: "ihearu-ai.firebaseapp.com",
                databaseURL: "https://ihearu-ai.firebaseio.com",
                projectId: "ihearu-ai",
                storageBucket: "ihearu-ai.appspot.com",
                messagingSenderId: "327948151541",
                appId: "1:327948151541:web:3748aabd0ba7bcd78033bc",
                measurementId: "G-ZH0TS8EMM8"
            });
        }
        console.log("firebase configured in main");
        firebase.analytics().logEvent(window.location.hostname);
    }
}
