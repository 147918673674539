<template>
  <v-container>
    <div>
      <h3 align="center">
        Please sign up below if you are interested to connect with us
      </h3>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
      <v-row>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field
            v-model="fname"
            label="* First Name"
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3" xl="3">
          <v-text-field 
            v-model="lname" 
            label="* Last Name"           
            :rules="[rules.required]"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field 
            v-model="company" 
            label="Organization"
            placeholder="(optional)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="* Email address"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-text-field
            v-model="phone"
            label="Phone number"
            placeholder="(optional)"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-checkbox
            v-model="demo"
            label="I am interested to see a Demo"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-checkbox
            v-model="sponsor"
            label="I am interested to become a sponsor"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-checkbox
            v-model="partner"
            label="I am interested to become a partner"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-checkbox
            v-model="updated"
            label="I want to stay updated"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="message"
            label="(optional) your message"
            counter
            maxlength="140"
            full-width
            single-line
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row justify="space-around" class="ma-3 pa-3">
        <v-col cols="1">
          <v-btn color="white" @click="submitted"> Cancel </v-btn>
        </v-col>
        <v-col cols="1">
          <v-btn color="accent" @click="submit"> Submit </v-btn>
        </v-col>
      </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
// import firebasePush from "../js/firebase"
import firebase from 'firebase'

import { Component } from "vue-property-decorator";

export default {

  props: {
    submitted: Function
  },
  data() {
    return {
      title: "Preliminary report",
      rules: {
        required: (value) => !!value || "Required",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      errorMessages: "",
      company: null,
      fname: "",
      lname: "",
      email: "",
      message: "",
      phone: null,
      demo: false,
      partner: false,
      sponsor: false,
      updated: true,
      formHasErrors: false,
      valid: false
    }
  },
  mounted() {
      if (!firebase.apps.length) {
            firebase.initializeApp({
                apiKey: "AIzaSyBtUetEMyGkl_oYK3pdHRNav0yFVvgM-YE",
                authDomain: "ihearu-ai.firebaseapp.com",
                databaseURL: "https://ihearu-ai.firebaseio.com",
                projectId: "ihearu-ai",
                storageBucket: "ihearu-ai.appspot.com",
                messagingSenderId: "327948151541",
                appId: "1:327948151541:web:3748aabd0ba7bcd78033bc",
                measurementId: "G-ZH0TS8EMM8"
            });
        }
        console.debug("form opened");
        firebase.analytics().logEvent(window.location.hostname + 'formOpened');

    },
  methods: {
    getForm() {
      return {
        fname: this.fname,
        lname: this.lname,
        email: this.email,
        phone: this.phone,
        demo: this.demo,
        partner: this.partner,
        sponsor: this.sponsor,
        updated: this.updated,
        message: this.message,
      }
    },
    submit () {
      const form = this.getForm();
      const ts=new Date().toISOString().split('T')[0];
      if (this.$refs.form.validate()) {
        firebase.database().ref('registrations/'+ts).push().set( form ).then(() => {
          this.submitted(true);
        });
        firebase.firestore().collection("mail").doc().set({
          to: ["contact@aihearu.com"], 
          message: {
            text: JSON.stringify(form, null, 4),
            subject:"AIHEARU web contact: "+form.email
          }
        }).then(() => {
          console.log("Successfully submitted form");
        }).catch(err => {
          console.log("Failed to submit form", err);
        });
      } else {
        console.log("not valid!")
      }
    }
  }
}
</script>