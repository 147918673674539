<template>
  <div>
    <v-container class="ma-0 pa-0" fluid>
      <v-parallax src="/imgs/bg_galaxy.jpg" height="300">
          <v-layout column align-center justify-center class="white--text">
            <h1 class="white--text mb-2 display-1 text-xs-center" style="font-weight: 900; text-shadow: 3px 2px #000000">
              We are currently in pilot phase
            </h1>
            <v-btn class="primary  mt-5" dark large @click="signin">
              Contact Us
            </v-btn>
            <h3 
              v-if="showThanks"
              class="white--text mb-2 text-xs-center" 
              style="font-weight: 900; text-shadow: 3px 2px #000000"
            >Thanks. Successfully received form. </h3>
          </v-layout>
        </v-parallax>
    </v-container>
    <div id="form">
      <ResponsiveForm v-if="showForm" :submitted="formSubmitted"/>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Component } from "vue-property-decorator"
import ResponsiveForm from "./ResponsiveForm.vue";

export default{
  name: "ResponsiveInterested",
  components: {ResponsiveForm},
  data () {
      return {
        showForm: false,
        showThanks: false
      }
  },
  mounted() {
    this.checkAutoForm();
  },
  methods: {
    checkAutoForm() {
      if (location.hash === "#contact") {
        this.showForm = true;
      }
    },
    formSubmitted(success) {
      if (success) {
        this.showThanks = true;
        console.log("Valid and submitted!");
     }
      this.showForm = false;
      this.showThanks = false;
      this.$vuetify.goTo('#contact');
    },
    signin() {
      this.showForm = true;
      this.showThanks = false;
      this.$vuetify.goTo('#form')
    }
  }

}
</script>
