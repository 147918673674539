






















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ResponsiveWave extends Vue {
  @Prop({default: 'white'}) readonly topColor!: string
  @Prop({default: 'white'}) readonly bottomColor!: string

}
