









































import Vue from 'vue'
import { Component } from "vue-property-decorator"
import firebase from 'firebase'

@Component
export default class ResponsiveActionIcons extends Vue {
    mounted () {
      this.scroll();
    }

    data () {
      return {
        items: [
        { text: 'Home', icon: 'mdi-home', jump: "home"},
        { text: 'Motivation', icon: 'mdi-lightbulb-on', jump: "motivation" },
        { text: 'Partners', icon: 'mdi-flask-outline', jump: "partners" },
        { text: "Whats New", icon: 'mdi-new-box', jump: "whatsNew" },
        { text: "Contact Us", icon: 'mdi-voice', jump: "contact" },
        { text: 'About us', icon: 'mdi-information-outline', jump: "about" }
      ],
        closeOnContentClick: true,
      }
    }

  get shrink() {
    return this.$vuetify.breakpoint.smAndDown
  }

  scroll () {
    window.onscroll = () => {
      const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

      if (bottomOfWindow) {
        firebase.analytics().logEvent(window.location.hostname + 'scrolledBottom');
        console.debug("Scrolled to bottom")
      }
    }
  }


  show() {
    firebase.analytics().logEvent(window.location.hostname + '-showMenu');
    console.log("show menu...")
  }

  jump(to: string) {
    firebase.analytics().logEvent(window.location.hostname + '-jumpMenu');
    this.$vuetify.goTo('#' + to);
  }

}
